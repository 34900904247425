export function SaveTheDateVideo() {
    return (
      <div className={`section p-4 serif rem16 rounded justify-content-center d-flex`} style={{}}>
        <div className='' style={{width:'96%', height:'auto', aspectRatio:'16/9'}}>
          <iframe src="https://player.vimeo.com/video/890385489?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" 
          frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen={true} 
          style={{top:'0',left:'0',width:'100%',height:'100%'}} title="SaveTheDate"/>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    );
  }