import { getFormattedDate, getTheDate } from "../utils/dateUtils";
import { toTitleCase } from "../utils/textUtils";
import { useState } from "react";
import { sectionHeaderClassName } from "../meta/styleMeta";
import eventRoles from "../data/eventRoles";
import { ViewNote } from "./ViewNote";
import { ProgramButton } from "./ProgramButton";
import flowerGirls from "../data/flowerGirls";
import ringBearer from "../data/ringBearer";
import { getAllFrontGuestTables } from "../utils/frontendWedding";
import { displayEntourageList } from "../data/constants";
import { Program } from "./Program";
import bibleBearer from "../data/bibleBearer";

function getParticipatingRoles() {
    return eventRoles
    .filter(role => role !== 'service_vendor')
    .filter(role => role !== 'coordinator')
    .filter(role => role !== 'host')
    .filter(role => role !== 'mc')
    .filter(role => role !== 'sponsors_proxy')
}

const nonParticipatingGuestPaired = [
    'Dr. Raymund Razonable',
    'Kathleen van der Heide',
    'Ethel Reyes',
    "Alleana Claveria",
    "Jehan Sapno"
];

const proxyMap = new Map();
proxyMap.set('Belinda Cadiente', 'Antonnette Elciario')

function getParticipatingGuestNames(role, guestList) {
    let roledGuest;
    if(role === 'flower_girls' || role === 'ring_bearer') {
      roledGuest = role === 'flower_girls' ? flowerGirls : ringBearer;
    } else if( role === 'bible_bearer') {
      return bibleBearer;
    } else {
      roledGuest = guestList?.filter(guest => guest.eventRole === role)
      .map(guest => {
        return guest.formalName.split(',').map(name => name.trim())
        .map(name => {
          return {
            "eventRole": guest.eventRole,
            "formalName" : name,
            "isAttending" : guest.isAttending
          }
        })
      })
      .reduce((prev, cur) => prev.concat(...cur))
      .filter(guest => !nonParticipatingGuestPaired.includes(guest.formalName))
    }
    return roledGuest;
}


export function Entourage({guestDetail}) {
    const viewingGuestDetail = guestDetail;
    const visibleToRole = ["host", "parents", "pastor", "sponsors", "sponsors_proxy", "best_man", "maid_of_honor", 
    "groomsmen", "bridesmaids", "coordinator", "flower_girls", "ring_bearer", "mc"];
    const isVisible = Date.now() >= displayEntourageList || visibleToRole.includes(viewingGuestDetail.eventRole);
    const [guestList, setGuestList] = useState(null);
    // const className = 'col-12 col-md-6 col-lg-4 col-xl-3'
    if(guestList === null) {
      getAllFrontGuestTables(setGuestList);
    }

    return (
        <div>
          <div className={sectionHeaderClassName}>Entourage</div>
          {!isVisible && (<p className='rem13 px-4'>This will be visible to all on {getFormattedDate(displayEntourageList)}</p>)}
          {isVisible && (
            <div className='ps-5'>
              { Date.now() <= getTheDate(2024, 3, 8, 8) && (<p className='h2'>
                <span className='italic bold'>(Tentative)</span> Finalized 1 Week before the day.</p>)}
              {
                getParticipatingRoles()
                ?.filter(role => role !== 'guest')
                .map((role,index) => {
                  return (
                    <div key={index}>
                        <span className='bold rem16'>{toTitleCase(role.replaceAll('_', ' '))}</span>
                        <ul className='row nobullets rem15'>
                          {
                            getParticipatingGuestNames(role, guestList)
                            ?.map((guest,index) => {
                              const attending = parseInt(guest.isAttending) === 1;
                              return (
                                <li className='col-12 col-lg-6' key={index}>
                                  <span className={(!attending && proxyMap.has(guest.formalName)) ? `italic` : ``}>{(!attending && !proxyMap.has(guest.formalName)) ? '' : guest.formalName}</span> 
                                  {proxyMap.has(guest.formalName) && (` to be proxy by ${proxyMap.get(guest.formalName)}`)} 
                                </li>
                              )}
                              )
                            }
                        </ul>
                      </div>
                    )
                  })
                }
                <ProgramButton viewingRole={viewingGuestDetail.eventRole} invitationId={viewingGuestDetail.invitationId}/>
            </div>
            )
          }
        </div>
    )
  }