import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import { getTheDate, getFormattedDate } from '../utils/dateUtils';

export function  CheckList() {
    const [itemList, setItemList] = useState(
        [
            { "id":"12321312", "description":"Task1", "val":true, "deadline": getTheDate(2024,3,15)},
            { "id":"21312312312", "description":"Task2", "val":false},
        ]
    );
    const checkListMap = new Map();

    function handle(itemValue) {
        checkListMap
        .set(itemValue.id, itemValue)
        console.log(checkListMap);
    }

    function save() {
        console.log(checkListMap);
    }

    return (
        <div className='container form-check'>
            {
                itemList.map((item,index) => {
                    return (
                        <CheckListItem index={index} key={index} id={item.id} description={item.description} val={item.val} deadline={item.deadline && (getFormattedDate(item.deadline))} handler={handle}/>
                    )
                })
            }
            <button className='btn btn-primary' onClick={() => save()}>Save</button>
        </div>
    );
}



function CheckListItem({ index, id, description, val, deadline, handler}) {
    // const [isChecked, setChecked] = useState(val);
    return (
        <div style={{background:`${index%2===0?'rgb(239,239,239)':'white'}`}}>
            <input className={`me-2 form-check-input`} type='checkbox' defaultChecked={val}
                onChange={event => {
                        const itemValue = {
                            "id": id,
                            "description": description,
                            "val": event.target.checked,
                            "deadline": deadline
                        };
                        handler(itemValue);
                    }}/>
            <span className='form-check-label mx-3 bold'>{description}</span>
            <span className='italic'>{deadline}</span>
        </div>
    );
}