const flowerGirls = [
    {
      "eventRole": "flower_girls",
      "formalName" : "Keisha Pena",
      "isAttending" : 1
    },
    {
      "eventRole": "flower_girls",
      "formalName" : "Alex Oriel",
      "isAttending" : 1
    }
];
export default flowerGirls;