import { useState } from "react";
import { getAllFrontGuestDetails } from "../utils/frontendWedding";

function compareTableNumber(a,b) {
    if(a.tableNumber < b.tableNumber) {
        return -1;
    } else if(a.tableNumber > b.tableNumber) {
        return 1;
    }
    return 0;
}

export function CsvGuestList() {
    const [guestDetails, setGuestDetails] = useState(null);
    const lines = [];
    const notCounted = ["Liam Henderson"];
    const children = ["Keisha Pena", "Alex Oriel", "Ethan James Panaligan"];
    const withAllergy = ["Marc Kevin Lagman", "Vinci Millena", "Dr. Allan Hawryluk", 
    "Nick Henderson", "Trizia Paragas", "Carren Shiwprasad", "Florence Elsesora"];
    if(guestDetails === null) {
        getAllFrontGuestDetails(setGuestDetails);
    }

    const specialFoodMap = new Map();
    specialFoodMap.set("Liam Henderson", "1 year old/ Will not be in reception");

    //header
    const header = []
    header.push("Table #");
    header.push("Guest Name");
    header.push("Adult");
    header.push("Child")
    header.push("Alt Child(3-10)");
    header.push("Special Meal Requirement");
    lines.push(header.join(","));

    const sortedByTable = guestDetails
        ?.filter(element => element.isAttending === 1)
        .sort((a,b) => compareTableNumber(a,b))
        .flatMap(element => {
            const res = element.formalName.split(",")
            .map(name => name.trim())
            .map(name => {
                const adultCount = !children.includes(name) && !notCounted.includes(name) ? 1 : '';
                const childCount = children.includes(name) && !notCounted.includes(name) ? 1 : '';
                if(children.includes(name)) {
                    console.log(name + ":" + children.includes(name))
                }
                const allergy = withAllergy.includes(name) ? element.allergy : 
                                    specialFoodMap.has(name) ? specialFoodMap.get(name) : '';
                return {
                    "tableNumber" : element.tableNumber === 0 ? "Head Table" : element.tableNumber,
                    "formalName" : name,
                    "guestAllocation" : adultCount,
                    "children" : childCount,
                    "allergy" : allergy
                }
            });
            return res;
        })
        .map(element => {

            const line = [];
            line.push(element.tableNumber);
            line.push(element.formalName);
            line.push(element.guestAllocation);
            line.push("");
            line.push(element.children);
            line.push(element.allergy === undefined ? ' ' : element.allergy);
            lines.push(line.join(","))
        });



    // console.log(lines);
    return (
        <textarea value={lines?.join("\n")} style={{width: '5in', height: '5in'}}/>
    );
}
