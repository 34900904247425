import { getTheDate } from '../utils/dateUtils';

export const giftNote = "With all we have, we've been truly blessed. Your presence and prayers are all we request. <br/>But if you desire to give nonetheless, a monetary gift is what we suggest.";

export const confirmBy = getTheDate(2024,1,12);
export const dDay = getTheDate(2024, 3, 15, 15, 45);
export const openBarDay = getTheDate(2024,3,14,21,0);
export const displayCeremonySeatingPlan = getTheDate(2024,3,14);
export const displayEntourageList = getTheDate(2024,3,14);
export const displayProgram = getTheDate(2024,3,14,5,0);

export const k = 'AIzaSyAlJP5Oprku0fPJEuG6xCi6dug-B81SG7w';