import { getAllGuestDetail, createGuest } from '../utils/backendWedding.js'
import eventRoles from '../data/eventRoles.js';
import { useState } from 'react';
import { EventTables } from './EventTables.js'

function TextInput({...props}) {
    return (
        <div className='row'>
            <label htmlFor={props.name}>{props.label}</label>
            <input name={props.name} type="text" placeholder={props.placeholder} defaultValue={props.defaultValue} onChange={props.onChange}/>
        </div>

    );
}

function Selection({name, selection, label = 'no label', onChange, defaultValue, container = 'div', containerClassName = 'row'}) {
    const Container = container;
    return (
        <Container className={containerClassName}>
            <label htmlFor={name}>{label}</label>
            <select name={name} onChange={onChange} defaultValue={defaultValue}>
            {selection.map(item => {
                return (<option key={item} value={item}>{item}</option>);
            })}
            </select>
        </Container>
    );
  }

  function TextArea({...props}) {
    return (
        <div className='row'>
            <label htmlFor={props.name}>{props.label}</label>
            <textarea name={props.name} placeholder={props.placeholder} onChange={props.onChange}></textarea>
        </div>
    );
  }

  function Button({...props}) {
    return (
        <div className='row'>
            <input type='button' {...props}/>
        </div>
    );
  }

function AddGuestForm({...props}) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const [guestName, setGuestName] = useState('');
    const [formalName, setFormalName] = useState('');
    const [guestAllocation, setGuestAllocation] = useState(1);
    const [tableNumber, setTableNumber] = useState(1);
    const [customMessage, setCustomMessage] = useState('');
    const [eventRole, setEventRole] = useState('guest');


    function addGuest() {
        const guestDetail = {
            "name": guestName,
            "formalName": formalName,
            "guestAllocation": parseInt(guestAllocation),
            "tableNumber": parseInt(tableNumber),
            "customMessage": customMessage,
            "eventRole": eventRole,
            "token":token
        }
        //clean
        guestDetail.name = guestDetail.name.trim();
        guestDetail.customMessage = guestDetail.customMessage.trim();
        if(guestDetail.name !== '') {
            createGuest(guestDetail, (guestDetail) => console.log(guestDetail.name + " added to guests"));
        } else {
            console.log("No name entered. Closing form")
        }
        props.buttonHandler();
    }

    return (
        <>
            <div style={{zIndex:'5', position:'fixed', top:'50%', left:'50%', transform:'translate(-50%,-50%)', backgroundColor:'aliceblue', padding:'30px'}}>
                <TextInput label="Guest Name" name="guestName" placeholder="Guest Name" defaultValue={guestName} onChange={(event)=>setGuestName(event.target.value)}/>
                <TextInput label="Formal Name" name="formalName" placeholder="Formal Name" defaultValue={formalName} onChange={(event)=>setFormalName(event.target.value)}/>
                <Selection container='div' name='guestAllocation' selection={[1,2,3,4,5,6]} label="Guest Allocation" onChange={(event) => setGuestAllocation(parseInt(event.target.value))}/>
                <Selection container='div' name='tableNumber' selection={[1,2,3,4,5,6,7,8,9,10]} label="Table Number" onChange={(event) => setTableNumber(parseInt(event.target.value))}/>
                <Selection container='div' name='eventRole' selection={eventRoles} label='Event Role' defaultValue='guest' onChange={(event) => setEventRole(event.target.value)}/>
                <TextArea name='customMessage' label='Custom Message (Optional)' placeholder='Custom Message (Optional)' onChange={(event) => setCustomMessage(event.target.value)}/>
                <Button value='Add Guest' onClick={addGuest}/>
                
            </div>
            <div style={{zIndex:'3', position:'fixed', top:'0px', bottom:'0px', left:'0px', right:'0px', backgroundColor:'rgba(0,0,0,0.7)' }}>
                {/* Nothing goes here, this is a cover layer */}
            </div>
        </>
    );
  }

  function Stats({attendingCount, notAttendingCount, attendingCountAdult, attendingCountChildren, undecidedCount, attendingWithAllergy, totalAllocated, invitationLinkCount}) {
    return (
        <>
            <span className='h4'>Stats (Seat count)</span>
            <table className='table table-striped table-bordered'>
                <thead>
                    <tr>
                        <th>Attending</th>
                        {/* <th>Not Attending</th> */}
                        <th>Undecided</th>
                        {/* <th>Attending + Undecided</th> */}
                        <th>Attending Adult</th>
                        <th>Attending Children (3-10)</th>
                        <th>Attending with Allergy</th>
                        <th>Total Attending Adult + Children</th>
                        <th>Invitation Link Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{attendingCount}</td>
                        {/* <td>{notAttendingCount}</td> */}
                        <td>{undecidedCount}</td>
                        {/* <td>{attendingCount + undecidedCount}</td> */}
                        <td>{attendingCountAdult}</td>
                        <td>{attendingCountChildren}</td>
                        <td>{attendingWithAllergy}</td>
                        <td>{totalAllocated}</td>
                        <td>{invitationLinkCount}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
  }

export function GuestList() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const [guestList, setGuestList] = useState(null);
    const [attendingCount, setAttendingCount] = useState(0);
    const [attendingCountAdult, setattendingCountAdult] = useState(0);
    const [attendingCountChildren, setattendingCountChildren] = useState(0);
    const [notAttendingCount, setNotAttendingCount] = useState(0);
    const [undecidedCount, setUndecidedCount] = useState(0);
    const [attendingWithAllergy, setAttendingWithAllergy] = useState(0);
    const [totalAllocated, setTotalAllocated] = useState(0);
    const [invitationLinkCount, setInvitationLinkCount] = useState(0);
    const [guestsOnDisplay, setGuestsOnDisplay] = useState(null);
    const [guestFormVisibility, setGuestFormVisibility] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('name')

  
    function getAllGuestHandler(guestList) {
        const filteredGuest = token === 'coordinator' ? guestList.filter(g => g.tableNumber <= 10) : guestList;
      setGuestList(filteredGuest);
      setGuestsOnDisplay(filteredGuest);
        const attending = filteredGuest?.filter(element => element.isAttending === 1)
        ?.reduce((accumulator, element) => accumulator + element.guestAllocation, 0);
        setAttendingCount(attending);
        const notAttending = filteredGuest?.filter(element => element.isAttending === 0)
        ?.reduce((accumulator, element) => accumulator + element.guestAllocation, 0);
        setNotAttendingCount(notAttending);
        const undecided = filteredGuest?.filter(element => element.isAttending === null)
        ?.reduce((accumulator, element) => accumulator + element.guestAllocation, 0);
        setUndecidedCount(undecided);
        const childrenCount = filteredGuest?.filter(element => element.isAttending === null || element.isAttending === 1)
        ?.reduce((accumulator, element) => accumulator + (element.children), 0);
        setattendingCountChildren(childrenCount);
        const adultCount = filteredGuest?.filter(element => element.isAttending === null || element.isAttending === 1)
        ?.reduce((accumulator, element) => accumulator + (element.guestAllocation - element.children), 0);
        setattendingCountAdult(adultCount);
        const attendingAllergy = filteredGuest?.filter(element => element.isAttending ===1 && element.allergy !== '')
        ?.reduce((accumulator, element) => accumulator + element.guestAllocation, 0);
        setAttendingWithAllergy(attendingAllergy);
        const total = filteredGuest?.filter(ele => ele.isAttending === 1)?.reduce((accumulator, element) => accumulator + element.guestAllocation, 0);
        setTotalAllocated(total);
        setInvitationLinkCount(filteredGuest?.length);
    }

    function filterDisplay(event) {
        let guestsToBeDisplayed;
        if(event.target.value === 'attending') {
            guestsToBeDisplayed = [...guestList]?.filter(element => element.isAttending === 1);
        } else if(event.target.value === 'notAttending') {
            guestsToBeDisplayed = [...guestList]?.filter(element => element.isAttending === 0);
        } else if(event.target.value === 'pending') {
            guestsToBeDisplayed = [...guestList]?.filter(element => element.isAttending === null);
        } else if (event.target.value === 'eventRole') {
            guestsToBeDisplayed = [...guestList]?.filter(element => element.eventRole !== 'guest');
        } else if (event.target.value === 'withChildren') {
            guestsToBeDisplayed = [...guestList]?.filter(element => element.children > 0);
        } else if (event.target.value === 'allergy') {
            guestsToBeDisplayed = [...guestList]?.filter(element => element.allergy !== '');
        } else if(event.target.value === 'sortAsc') {
            guestsToBeDisplayed = [...guestsOnDisplay]
            .sort((a,b) => String(a[searchCriteria]).toLowerCase() < String(b[searchCriteria]).toLowerCase() ? -1 : String(a[searchCriteria]).toLowerCase() > String(b[searchCriteria]).toLowerCase() ? 1 : 0);
        } else if(event.target.value === 'sortDesc') {
            guestsToBeDisplayed = [...guestsOnDisplay]
            .sort((a,b) => String(a[searchCriteria]).toLowerCase() > String(b[searchCriteria]).toLowerCase() ? -1 : String(a[searchCriteria]).toLowerCase() < String(b[searchCriteria]).toLowerCase() ? 1 : 0);
        } else {
            guestsToBeDisplayed = guestList;
        }
        setGuestsOnDisplay(guestsToBeDisplayed);
    }

    function searchFilter(event) {
        const searchValue = event.target.value
        const guestsToBeDisplayed = [...guestList]?.filter(element => {
            return String(element[searchCriteria])?.toLowerCase().includes(searchValue.toLowerCase());
        })
        setGuestsOnDisplay(guestsToBeDisplayed);
    }

    function addGuestButtonHandler() {
        setGuestFormVisibility(!guestFormVisibility);
        setTimeout(()=> updateGuestList(), 2000);
    }

    if(token === null || token === '') {
        window.location.href="/lost"
    }
  
    if(guestList === null && token !== null) {
      updateGuestList();
    }

    function updateGuestList() {
        getAllGuestDetail(token, getAllGuestHandler);
    }

    const urlHost = window.location.host;
    const hostName = urlHost.includes("localhost") ? "http://localhost:3000" : "https://kristellevirson.ca";

    return (
      <div className='p-5'>
        <Stats attendingCount={attendingCount} notAttendingCount={notAttendingCount}
        attendingCountAdult={attendingCountAdult} attendingCountChildren={attendingCountChildren} 
        undecidedCount={undecidedCount} attendingWithAllergy={attendingWithAllergy} 
        totalAllocated={totalAllocated} invitationLinkCount={invitationLinkCount}/>
        <EventTables guests={guestList}/>
        {guestFormVisibility && (<AddGuestForm buttonHandler={addGuestButtonHandler}/>)}
        {
            (token !== 'coordinator' && token !== '') && (
                <div className='row'>
                    <button className='col-2' onClick={()=> setGuestFormVisibility(!guestFormVisibility)}>Add Guest</button>
                </div>
            )
        }
        <span className='h4'>Guest Lists</span>
        {
            guestList && 
        (
            <div className='py-5'>
                <menu>
                    <Selection name="criteria" selection={['name', 'customMessage', 'guestAllocation', 'tableNumber', 'eventRole', 'allergy']} 
                    label='Search Criteria' container='div' containerClassName='' defaultValue={searchCriteria}
                    onChange={(event) => setSearchCriteria(event.target.value)}/>
                    <input type="text" placeholder='type to search' onChange={searchFilter}/>
                    <button onClick={filterDisplay} value="attending">Attending</button>
                    <button onClick={filterDisplay} value="notAttending">Not Attending</button>
                    <button onClick={filterDisplay} value="pending">Pending</button>
                    <button onClick={filterDisplay} value="eventRole">With Role</button>
                    <button onClick={filterDisplay} value="withChildren">With Children</button>
                    <button onClick={filterDisplay} value="allergy">With Allergy</button>
                    <button onClick={filterDisplay} value="sortAsc">Sort Asc</button>
                    <button onClick={filterDisplay} value="sortDesc">Sort Desc</button>
                    <button onClick={filterDisplay} value="reset">Reset</button>
                </menu>
            </div>
        )
        }
        <table className='table table-striped table-bordered'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Full Name</th>
              {
                (token !== 'coordinator' && token !== '') &&
                (
                    <th>Custom Message</th>
                )
              } 
              <th>Guest Allocation</th>
              <th>Children</th>
              <th>Is Attending</th>
              <th>Table Number</th>
              <th>Last Update</th>
              <th>Event Role</th>
              <th>Allergy</th>
              {(token !== 'coordinator' && token !== '') && (<th>Invitation Link</th>)}
            </tr>
          </thead>
          <tbody>
            {guestsOnDisplay && (guestsOnDisplay.map(element => {
              return (
                <tr key={element.invitationId}>
                  <td>{element.name}</td>
                  <td>{element.formalName}</td>
                    {
                        (token !== 'coordinator' && token !== '') &&
                        (
                        <td>{element.customMessage}</td>
                        )
                    }
                  <td>{element.guestAllocation}</td>
                  <td>{element.children}</td>
                  <td>{element.isAttending === null ? "Have not decided" : element.isAttending === 1 ? 'Attending' : 'Not Attending'}</td>
                  <td>{element.tableNumber}</td>
                  <td>{element.lastChanged}</td>
                  <td>{element.eventRole}</td>
                  <td>{element.allergy}</td>
                  {
                    (token !== 'coordinator' && token !== '') &&
                    (
                        <td><a target='_blank' href={`${hostName}/?invitationId=${element.invitationId}`}>{`${hostName}/?invitationId=${element.invitationId}`}</a></td>
                    )
                  }
                </tr>
                );
              }))}
          </tbody>
        </table>
      </div>
    );
  }

  

  