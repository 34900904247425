import { useState, useRef, forwardRef } from "react";
import { sectionHeaderClassName, sectionDivClassName } from "../meta/styleMeta";
import { createGuestMessage, getGuestMessage } from "../utils/backendWedding";
import { getAllFrontGuestMessages } from "../utils/frontendWedding";

export function GuestBook(props, ref) {
    const [guestMessage, setGuestMessage] = useState(null);
    const guestbookDialog = useRef();
    const guest = props.guest;
  
    function updateGuestMessage() {
        getGuestMessage(setGuestMessage);
    }
  
    if(guestMessage === null) {
        updateGuestMessage();
    }
  
    return (
      <div className={sectionDivClassName} ref={ref}>
        <div className={sectionHeaderClassName}>Guest Book</div>
        <div className='py-3'>
          <WriteGuestbookDialog guest={props.guest} ref={guestbookDialog} refreshHandle={updateGuestMessage}/>
          {guest && (<button className='button-menu border rounded' 
          onClick={() => guestbookDialog.current.showModal()} title='Leave a message'>Leave a Message</button>)}
          
        </div>
        <div className='row'>
          {guestMessage && guestMessage.map(item => {
            return (
              <div className='p-5 col-12 col-xl-6 rounded guest-message-box' key={item.id}>
                <div className='rem16'><q>{item.message}</q></div>
                <div className='text-end fw-bold rem-20 underline'>{item.displayName}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
}

  const WriteGuestbookDialog = forwardRef(function WriteGuestbookDialog(props,ref) {
    const guest = props.guest;
    const displayNameRef = useRef();
    const messageRef = useRef();
    const [status, setStatus] = useState('');
  
    function submitHandler() {
      const displayName = displayNameRef.current.value.trim();
      const message = messageRef.current.value.trim();
      if(displayName !== '' && message !== '') {
        const data = {
          "invitationId": guest?.invitationId,
          "displayName" : displayNameRef.current.value.trim(),
          "message" : messageRef.current.value.trim()
        }
        messageRef.current.value = '';
        setStatus('');
        ref.current.close();
        createGuestMessage(data, () => setTimeout(props.refreshHandle(), 2000))
      } else {
        setStatus('Field empty');
      }
    }
  
    return (
      <dialog ref={ref} className='rounded text-theme'>
        <div className='form'>
          <div className='row'>
            <p>Name</p>
            <input type="text" name="displayName" defaultValue={guest?.name} placeholder='Your Name' ref={displayNameRef}/>
          </div>
          <div className='row'>
            <p>Message</p>
            <textarea name='message' placeholder='Your message' ref={messageRef} /> 
          </div>
          <div>{status}</div>
          <div className='row pt-2'>
            <button className='col rounded button-menu border' onClick={submitHandler}>Submit</button>
            <button className='col rounded button-menu border' onClick={() => ref.current.close()}>Cancel</button>
          </div>
        </div>
      </dialog>
    );
});