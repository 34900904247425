import { useState } from "react";
import { getAllFrontGuestTables } from "../utils/frontendWedding";
import { sectionHeaderClassName } from "../meta/styleMeta";
import { displayCeremonySeatingPlan } from "../data/constants";
import { ViewNote } from "./ViewNote";
import { getFormattedDate } from "../utils/dateUtils";

const sideChairAmount = 40;
const rightSeats = [];
const leftSeats = [];
const podium = [];
const couple = ["Kristelle Bondad","Virson Rulona"];

function getInitials(name) {
    if(name === '-' || name === '*') {
      return name === '*' ? '*' : name;
    }
    if(name === 'Sander van der Heide') {
      return 'S.H.'
    }
    let splitName = name.split(" ");
    let titleFiltered = splitName.filter(part => !part.includes("."));
    let initials = titleFiltered.map(filteredName => {
      return filteredName.substr(0,1);
    });
    let joinedInitials = initials.join(".") + ".";
    return joinedInitials;
  }

function fillUpSeats(showNonParticipant, guestList) {
    if(podium.length < 1) {
        podium.push("Rev. Solomon Reyes")
    }
    if(leftSeats<=40) {
        
        leftSeats.push("Antonnette Elciario");
        leftSeats.push("Dr. Digna Sabaot");
        leftSeats.push("Thiru Chelvanaigum");
        leftSeats.push("Evangeline Bondad");
        leftSeats.push("Beatrice Villanueva");
        leftSeats.push("Bianca Villanueva");
        leftSeats.push("Bernice Villanueva");
        leftSeats.push("Krishelle Bondad");
        leftSeats.push("Allen Pena");
        leftSeats.push("Keisha Pena");
        leftSeats.push("Marcel Pena");
        leftSeats.push("Trizia Paragas");
        leftSeats.push("-");
        leftSeats.push("Precy Oriel");
        leftSeats.push("Alex Oriel");
        leftSeats.push("Harym Oriel");
    }
    if(rightSeats<=40) {
        rightSeats.push("Celina Rulona");
        rightSeats.push("Virancis Rulona");
        rightSeats.push("Sander van der Heide");
        rightSeats.push("Edward Macadangdang");
        rightSeats.push("Marc Kevin Lagman");
        rightSeats.push("Khristian Teves");
        rightSeats.push("Kristien Bondad");
        rightSeats.push("Bryan Villanueva");
        rightSeats.push("Alec Dones");
        rightSeats.push("Christopher Sewitt");
        rightSeats.push("-");
        rightSeats.push("-");
        rightSeats.push("Nick Henderson");
        rightSeats.push("Liam Henderson");
        rightSeats.push("Clarissa Mejia-Henderson");
        rightSeats.push("-");
        rightSeats.push("Randy Panaligan");
        rightSeats.push("Ethan James Panaligan");
        rightSeats.push("Cherween Panaligan");
        
    }

    const allocated = [...leftSeats, ...rightSeats, ...podium, ...couple];

    if(showNonParticipant) {
        let filteredGuestList = guestList?.filter(guest => guest.isAttending === 1)
        ?.flatMap(guest => guest.formalName.split(",").map(name => name.trim()))
        ?.filter(guestName => !allocated.includes(guestName));
        
        let filteredGuestListSplitNum = filteredGuestList?.length/2;
        filteredGuestList?.forEach((element,index) => {
            if(index<filteredGuestListSplitNum) {
            leftSeats.push(element)
            } else {
            rightSeats.push(element);
            }
        })

    }
    while(leftSeats.length < sideChairAmount) {
        leftSeats.push("*");
    }

    while(rightSeats.length < sideChairAmount) {
        rightSeats.push("*");
    }
    return allocated;
}

export function CeremonySeatingPlan({currentGuest, showNonParticipant}) {
    const [guestList, setGuestList] = useState(null);
    const currentGuestFormalNameSplit = currentGuest.formalName.split(",").map(name => name.trim());
    const divHeight = '3rem';
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const visibleToRoles = ["host", "parents", "pastor", "sponsors", "sponsors_proxy", "best_man", "maid_of_honor", 
        "groomsmen", "bridesmaids", "coordinator", "flower_girls", "ring_bearer", "mc"];
    const isVisible = Date.now() >= displayCeremonySeatingPlan || visibleToRoles.includes(currentGuest.eventRole);

    window.addEventListener("resize", function(event) {
        setWindowWidth(window.innerWidth);
    });
    
    if(guestList === null) {
        getAllFrontGuestTables(setGuestList);
    }
    const allocatedSeats = fillUpSeats(showNonParticipant, guestList);
    const yourNameOnAllocatedSeats = currentGuest.formalName?.split(",")
        ?.map(name => name.trim())
        .filter(name => allocatedSeats.includes(name));
  
    return (
      <div className='h5'>
        <div className={sectionHeaderClassName}>Ceremony Seating Plan</div>
        {
            !isVisible && (
                <div className="p-4">This will be visible to all on {getFormattedDate(displayCeremonySeatingPlan)}</div>
            )
        }
        {
            isVisible && 
            (
                <div>
                    <div className=''>
                    <ul>
                        <li>The <span className='bold'>first four (4) rows are reserved for the entourage</span>.</li>
                        <li>If you are <span className='bold'>not part of the entourage</span> please seat from rows five (5) to ten (10). These seats are marked <span className='bold' style={{color:`green`}}>Green</span> below</li>
                        <li>If your name is listed on the <span className='bold'>entourage or the parent of the flowergirls and the ringbearer</span> your seats will be marked <span className='bold' style={{color:'red'}}>Red</span> on the first four (4) rows.</li>
                    </ul>
                    {yourNameOnAllocatedSeats.length > 0 && (
                        <p className="bold p-4"><span style={{color:'red'}}>{yourNameOnAllocatedSeats.join(", ")}</span> {yourNameOnAllocatedSeats.length > 1 ? 'are' : 'is'} to be {currentGuest.eventRole === 'host' || currentGuest.eventRole === 'pastor' ? ' standing' : ' seated'} on the 
                        {
                            currentGuest.eventRole === 'host' || currentGuest.eventRole === 'pastor' ? ' position ' : ` ${yourNameOnAllocatedSeats.length > 1 ? 'seats' : 'seat'} `
                        }
                        marked in Red
                            {/*currentGuest.eventRole === 'host' || currentGuest.eventRole === 'pastor' ? 'position' : 'seat' marked in Red*/}
                        </p>
                    )}
                    </div>
                    <div className='xxxs-rem04 xxs-rem05 xs-rem06 sm-rem07 md-rem06 lg-rem07 xl-rem09 rem10 p-2'>
                    <div className='row justify-content-center'>
                        <div className='col-2 border-steelblue text-center'>
                        {podium.map((person, index) => {
                            const placeorder = -1 * ((index ) % 4) + 5;
                            return (
                            // <div className={`border-black`} title={person} key={"left" + index}>{getInitials(person)}</div>
                            <div className={``} 
                            title={person} 
                            key={"podium" + index} 
                            style={{
                                background:`${currentGuestFormalNameSplit.includes(person)? 'red' : 'none'}`,
                                color: `${currentGuestFormalNameSplit.includes(person)? 'white' : 'none'}`,
                                height: `${divHeight}`,
                                overflowWrap:'break-word'
                            }}>
                                {windowWidth<=768 ? getInitials(person) : person}
                                </div>
                            )
                            })}
                            <div>Podium</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-5'>
                        <div className='row border-steelblue'>
                            {leftSeats.map((person, index) => {
                            return (
                                <div className={`col-3 border-steelblue text-center`} 
                                title={person} 
                                key={"left" + index} 
                                style={{
                                background:`${currentGuestFormalNameSplit.includes(person)? 'red' : person === '*'? 'green' : 'none'}`,
                                color: `${currentGuestFormalNameSplit.includes(person) || person === '*'? 'white' : 'none'}`,
                                height: `${divHeight}`,
                                overflowWrap:'break-word'
                                }}>
                                    {windowWidth<=768 ? getInitials(person) : person}
                                </div>
                                )
                            })}
                        </div>
                        </div>
                        <div className='col text-center'>
                            <div className="row">
                                {couple.map((person, index) => {
                                const placeorder = -1 * ((index ) % 4) + 5;
                                return (
                                // <div className={`border-black`} title={person} key={"left" + index}>{getInitials(person)}</div>
                                <div className={`col-6`} 
                                title={person} 
                                key={"aisle" + index} 
                                style={{
                                    background:`${currentGuestFormalNameSplit.includes(person)? 'red' : 'none'}`,
                                    color: `${currentGuestFormalNameSplit.includes(person)? 'white' : 'none'}`,
                                    height: `${divHeight}`,
                                    overflowWrap:'break-word'
                                }}>
                                    {windowWidth<=768 ? getInitials(person) : person}
                                    </div>
                                )
                                })}
                            </div>
                        Aisle
                        </div>
                        <div className='col-5'>
                        <div className='row'>
                            {rightSeats.map((person, index) => {
                            return (
                                <div className={`col-3 border-steelblue text-center`} 
                                title={person} 
                                key={"right" + index} 
                                style={{
                                background:`${currentGuestFormalNameSplit.includes(person)? 'red' : person === '*'? 'green' : 'none'}`,
                                color: `${currentGuestFormalNameSplit.includes(person) || person === '*' ? 'white' : 'none'}`,
                                height: `${divHeight}`,
                                overflowWrap:'break-word'
                                }}>
                                    {windowWidth<=768 ? getInitials(person) : person}
                                    </div>
                                )
                            })}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
        
      </div>
    ); 
  }