import { useState } from 'react';
import { getAllGuestTables } from '../utils/backendWedding.js';
import 'bootstrap/dist/css/bootstrap.css';


const minTableNum = 0;
const maxTableNum = 10;

export function range(start,end) {
    let arr = [];
    for (let i = start; i <= end; i++) {
        arr.push(i);
    }
    return (arr);
}

export function EventTables({instance = 'none',guests}) {

    const tables = range(minTableNum,maxTableNum);
    const formalNames = breakFormalNames(guests);
    return (
        <div className='row my-4'>
            <div className='row'>
                <div className='col-6 col-sm-4 col-md-2' style={{color: 'green', fontWeight: 'bold', border:'1px solid black'}}>Attending</div>
                <div className='col-6 col-sm-4 col-md-2' style={{border:'1px solid black'}}>Pending</div>
            </div>
            {
                tables.map((table,index) => {
                    const tableGuestsDetailed = guests?.filter(guest => parseInt(guest.tableNumber) === parseInt(table))
                                .filter(guest => guest.isAttending === null || parseInt(guest.isAttending) === 1);
                    const tableGuests = formalNames?.filter(guest => parseInt(guest.tableNumber) === parseInt(table))
                                .filter(guest => guest.isAttending === null || parseInt(guest.isAttending) === 1);
                    const persons = tableGuestsDetailed?.reduce((accumulator, guest) => accumulator + parseInt(guest.guestAllocation), 0);
                    const children = tableGuestsDetailed?.reduce((accumulator, guest) => accumulator + parseInt(guest.children), 0);
                    const adults = persons - children;
                    return (
                        <div key={instance + table + index} className='col-12 col-md-6 col-xl-6' style={{border:'1px solid black'}}>
                            <div className='h5 row'>
                                <span className='col'>{table === 0 ? 'Head Table' : `Table ${table}`}</span>
                                {
                                    instance != 'public' && (
                                        <>
                                            {/* <span className='col'>{persons + " person"}</span> */}
                                            <span className='col'>{`${adults} ${adults > 1 ? 'adults' : 'adult'}`}</span>
                                            <span className='col'>{`${children} ${children > 1 ? 'children' : 'child'}`}</span>
                                        </>
                                    )
                                }
                                 
                            </div>
                            <ul>
                                {
                                    tableGuests &&
                                    (tableGuests.map((guest, index) => {

                                            return (
                                                <li className={`h6 row`} key={'table' + table + instance + index}>
                                                    <span className="col" style={{color:`${parseInt(guest.isAttending) === 1 && 'green'}`, fontWeight:`${parseInt(guest.isAttending) === 1 && 'bold'}`}}>
                                                        {guest.formalName}
                                                    </span>
                                                </li>
                                            );
                                        }))
                                }
                            </ul>
                        </div>
                    
                    );
                })
            }

        </div>
    );
}

function breakFormalNames(guests) {
    const formalNameGuests = [];
    guests?.map(guest => {
        guest?.formalName.split(",")
        ?.map(name => {
            formalNameGuests.push(
                {
                    "formalName" : `${name.trim()}`, 
                    "tableNumber" : guest.tableNumber,
                    "isAttending" : guest.isAttending
                })
        })
    })
    return formalNameGuests; 
}

function PublicEventTables({guests}) {
    

    const tables = range(minTableNum,maxTableNum);
    const formalNames = breakFormalNames(guests);

    return (
        <div className='row my-4'>
            {
                tables?.map((table,index) => {
                    const tableGuests = formalNames?.filter(guest => parseInt(guest.tableNumber) === parseInt(table))
                                .filter(guest => guest.isAttending === null || parseInt(guest.isAttending) === 1);
                    return (
                        <div key={table + index} className='col-12 col-md-6 col-xl-4' style={{border:'1px solid black'}}>
                            <div className='h5 row'>
                                <span className='col'>{table === 0 ? 'Head Table' : `Table ${table}`}</span>
                            </div>
                            <ul>
                                {
                                    tableGuests &&
                                    (tableGuests.map((guest, index) => {

                                            return (
                                                <li className={`h6 row`} key={'table' + table + index}>
                                                    <span className="col" style={{color:`${parseInt(guest.isAttending) === 1 && 'green'}`, fontWeight:`${parseInt(guest.isAttending) === 1 && 'bold'}`}}>
                                                        {guest.formalName}
                                                    </span>
                                                </li>
                                            );
                                        }))
                                }
                            </ul>
                        </div>
                    
                    );
                })
            }

        </div>
    );
}

export function EventTablesView() {
    const [guestList, setGuestList] = useState(null);

    function handleGuestList(guestList) {
        setGuestList(guestList);
    }

    if(guestList === null) {
        getAllGuestTables(handleGuestList);
    }

    return (
        <div>
            <PublicEventTables guests={guestList}/>
        </div>
    );
}

export function EventTablesViewDetailed() {
    const [guestList, setGuestList] = useState(null);

    function handleGuestList(guestList) {
        setGuestList(guestList);
    }

    if(guestList === null) {
        getAllGuestTables(handleGuestList);
    }

    return (
        <div>
            <EventTables guests={guestList}/>
        </div>
    );
}

export function EventTablesViewPrint() {
    const urlParams = new URLSearchParams(window.location.search);
    const width = urlParams.get("width");
    const length = urlParams.get("length");
    const fontColor = urlParams.get("fontColor");
    const fontFamily = urlParams.get("fontFamily");
    const headerFontSize = urlParams.get("headerFontSize");
    const nameFontSize = urlParams.get("nameFontSize");
    const paperLength = urlParams.get("paperLength")
    const [guestList, setGuestList] = useState(null);

    function handleGuestList(guestList) {
        setGuestList(guestList);
    }

    if(guestList === null) {
        getAllGuestTables(handleGuestList);
    }

    return (
        <div>
            <PrintedEventTables guests={guestList} width={width} length={length} headerFontSize={headerFontSize} nameFontSize={nameFontSize} paperLength={paperLength} fontColor={fontColor} fontFamily={fontFamily}/>
        </div>
    );
}


function PrintedEventTables({guests, width = 4, length = 5, headerFontSize = .5, nameFontSize = .3, paperLength = 11, fontColor = 'black', fontFamily = 'serif'}) {
    const tables = range(minTableNum+1,maxTableNum);
    const formalNames = breakFormalNames(guests);
    console.log(width + " inches x " + length + " inches");
    const breakAfterEvery = Math.floor(paperLength/(length));
    const headerFontFamily = fontFamily;
    const nameFontFamily = 'cursive'/*fontFamily*/;
    

    return (
        <div className='row my-4'>
            {
                tables?.map((table,index) => {
                    const tableGuests = formalNames?.filter(guest => parseInt(guest.tableNumber) === parseInt(table))
                                .filter(guest => guest.isAttending === null || parseInt(guest.isAttending) === 1);
                    
                    return (
                        <>
                        <div className='col steelblue italic' key={`printed_table${index}` }>
                            <div className='d-flex justify-content-center align-items-center' 
                            style={{
                                border:'.03in dotted black', 
                                width:`${width}in`, 
                                height:`${length}in`, 
                                margin:'.02in', 
                                lineHeight:`1.35`,
                                backgroundImage: "url('https://t3.ftcdn.net/jpg/05/97/49/08/360_F_597490871_JhERmRVPR2o9dDBRKxYpvXbQzr5POTw1.jpg')",
                                backgroundSize: 'cover'
                            
                            }}>
                                <div className='' 
                                    style={{
                                        border:`solid 1px ${fontColor}`, 
                                        width:`90%`, 
                                        height:`90%`,
                                        borderRadius:`2%`,
                                        background: 'rgba(255,255,255,.6)'
                                        
                                    }}>
                                    <div className='row'>
                                        <div className='col text-center' 
                                        style={{
                                            fontSize:`${headerFontSize}in`,
                                            fontFamily:`${headerFontFamily}`,
                                            fontWeight:`bold`,
                                            color:`${fontColor}`
                                            
                                        }}>
                                            {`TABLE ${table}`}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {
                                            tableGuests &&
                                            (
                                                tableGuests.map((guest, index) => 
                                                {
                                                return (
                                                    <div className={`col-12 text-center`} key={'table' + table + index}>
                                                        <span className="text-center mx-4" 
                                                        style={{
                                                            fontFamily:`${nameFontFamily}`, 
                                                            fontWeight:`bold`, 
                                                            fontSize:`${nameFontSize}in`, 
                                                            color:`${fontColor}`
                                                        }}>
                                                             {guest.formalName} 
                                                        </span>
                                                    </div>
                                                );
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                (index+1)%breakAfterEvery === 0 &&
                                (
                                    <div className='pagebreak'></div>
                                )
                            }
                        </div>
                        </>
                    
                    );
                })
            }

        </div>
    );
}