const vendorsList = [
    {
      "role" : "Photographer",
      "name" : "Julie Lising",
      "contact" : "416-704-1981",
      "entity" : "Digital Studio Asia",
      "facebook" : "https://www.facebook.com/digitalatudioasia/",
      "notes" : "Covers photo from Bridal prep to end of program"
    },
    {
      "role" : "Videographer",
      "name" : "Noel Lising",
      "contact" : "416-704-1981",
      "entity" : "Digital Studio Asia",
      "facebook" : "https://www.facebook.com/digitalatudioasia/",
      "notes" : "Covers photo from Bridal prep to end of program"
    },
    {
      "role" : "DJ",
      "name" : "Joseph Gascon",
      "contact" : "647-542-3143",
      "entity" : "JG Audio",
      "facebook" : "https://www.facebook.com/www.jgaudio.ca/",
      "notes" : "Also known as Kuya Bong. Covers DJing from 6pm to end of party"
    },
    {
      "role" : "MC",
      "name" : "Danilo Gonzales",
      "facebook" : "https://www.facebook.com/danilo.h.gonzales",
      "notes" : "Also known as Tito Dan, Family friend of the groom"
    },
    {
      "role" : "Photobooth provider",
      "name" : "Vergel Espina",
      "facebook" : "https://www.facebook.com/vergelespina",
      "notes" : ""
    },
    {
      "role" : "Wedding Coordinator",
      "name" : "Vanessa Erpelo",
      "contact" : "",
      "email" : "info@eventsbyve.com "
    }
  ]

  export default vendorsList;