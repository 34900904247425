export function Overlays() {
    //This are not relative to container
    return (
      <>
        <div className='overlay'>
        </div>
        <div className='overlay2 logo display-6'>
          <span>KRISTELLE</span><br/>
          <span>&</span><br/>
          <span>VIRSON</span>
        </div>
      </>
    );
}