import { useState } from "react";
import { ImageBlur } from "./ImageBlur";
export function GalleryImage({image, thumb = false}) {
    const [fullScreen, setFullScreen] = useState(false);
    const img = thumb ? thumbReplace(image) : image;
  
    function thumbReplace( img ) {
      return img.replace(".jpg", "_thumb.jpg")
              .replace(".JPG", "_thumb.JPG")
              .replace(".png", "_thumb.png")
              .replace(".PNG", "_thumb.PNG")
              .replace(".jpeg", "_thumb.jpeg")
              .replace(".JPEG", "_thumb.JPEG");
    }
  
    return (
      <>
        <div className='d-flex col-12 col-lg-6 col-xxl-4 gallery-block p-4'
        style={{paddingTop:'100%', boxShadow:'0px 0px 5px 5px white inset', backgroundColor:'aliceblue', boxShadow:'0px 0px 10px 10px white inset'}} >
          <div className='d-flex ratio ratio-1x1' style={{overflow:'hidden'}}>
            <ImageBlur style={{}} className='rounded' src={img} onClick={() => setFullScreen(!fullScreen)} backgroundColor={'aliceblue'}/>
          </div>
        </div>
  
        <div className={`${fullScreen ? '' : 'd-none'}`} style={{backgroundColor:' rgba(240,255,255, 0.9)', position:'fixed', zIndex:'1001', top:'0px', left:'0px', right:'0px', bottom:'0px', height:`${window.innerHeight}px`, boxShadow:'0px 50px 10px 10px skyblue', overflow:'hidden'}}>
          <button className='button-menu' onClick={() => setFullScreen(!fullScreen)}>Close</button>
          <div className='justify-content-center ratio ratio-1x1' style={{height:`${window.innerHeight * .95}px`,}}>
            {/* <div style={{position:'fixed', top:'50%', left:'50%', transform:'trasnlate(-50%,-50%)', width:'100%', height:'100%'}}> */}
              <ImageBlur style={{}} className='rounded' src={image} onClick={() => setFullScreen(!fullScreen)} backgroundColor={'aliceblue'}/>
            {/* </div> */}
            {/* <img src={image} style={{objectFit:'contain', objectPosition:'center',backgroundColor:'aliceblue'}} */}
            {/* onClick={() => setFullScreen(!fullScreen)}/> */}
          </div>
        </div>
      </>
    );
  }