import vendorsList from '../data/vendorsList';

export function Vendors ({exclude = []}) {
    return (
      <div>
        <p className='rem16'>Vendors List</p>
        {
          vendorsList
          .filter(vendor => !exclude.includes(vendor.role))
          .map((vendor,index) => {
            return (
              <div className='row' style={{border:`1px solid steelblue`}} key={`vend${index}`}>
                <div className='col-12 col-xl-6 bold'>{vendor.role}</div>
                <div className='col-12 col-xl-6'>{vendor.name}</div>
                {vendor.contact && (<div className='col-12 col-xl-6'>{vendor.contact}</div>)}
                {vendor.entity && (<div className='col-12 col-xl-6 underline'>{vendor.entity}</div>)}
                {vendor.email && (<div className='col-12 col-xl-6'>{vendor.email}</div>)}
                {vendor.facebook && (<div className='col-12 col-xl-6'><a href={vendor.facebook} target='_blank'>{vendor.facebook.replace("https://www.facebook.com","fblink")}</a></div>)}
                <div className='col-12 col-xl-12 italic'>{vendor.notes}</div>
              </div>
            )
          }) 
        }
      </div>
    )
  }