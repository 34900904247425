import { useState } from "react";
import { GalleryImage } from "./GalleryImage";
import { getGalleryImages } from "../utils/backendWedding";

export function GalleryTopic ({title, topic, src = null, thumb = false}) {
    //when thumb=true there is a smaller resolution picture available to view as thumbnail
    const [images, setImages] = useState(src);
    if(images === null) {
      getGalleryImages(topic, setImages);
    }
    return (
      <div className='d-flex flex-row flex-wrap'>
        <div className='col-12 text-capitalize display-6 underline'>{title}</div>
          { 
            images !== null && images?.length > 0 ?
            images.map((item, index) => {
              return (
                <GalleryImage image={item} key={topic + index} thumb={thumb}/>
              );
            }) 
            : <span className='rem13'>No Image Available</span>
          }
      </div>
    );
  }