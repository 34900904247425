import ATTIRE from '../data/attire';
import eventRoles from '../data/eventRoles';
import { sectionDivClassName, sectionHeaderClassName } from '../meta/styleMeta';
import { Announcement } from './Announcement';
import { Attire } from './Attire';
import { Entourage } from './Entourage';
import { CeremonySeatingPlan } from './CeremonySeatingPlan';
import { CoordinatorView } from './CoordinatorView';
import { LocationInfo } from './LocationInfo';
import { Gifts } from './Gifts';
import { TimeInfo } from './TimeInfo';
import OpenBar from './OpenBar';
import { getTheDate } from '../utils/dateUtils';
import { Vendors } from './Vendors';
import { ProgramView } from './ProgramDeprecated';

function getParticipatingRoles() {
    return eventRoles
    .filter(role => role !== 'service_vendor')
    .filter(role => role !== 'coordinator')
    .filter(role => role !== 'host')
    .filter(role => role !== 'pastor')
}

export function GuestInformation ({...props}, ref) {
    const printable = props.printable;
    const guestDetail = props.currentGuest;
    const guestRole = guestDetail?.eventRole;
    const attire = ATTIRE;
    return (
      <div className={sectionDivClassName} ref={ref}>
        <Announcement/>
        {guestDetail &&
        (
          <>
            <div className={sectionHeaderClassName}>Attire</div>
            {guestRole !== 'serviceVendor' && (<p>{attire.common.description}</p>)}
            <p>{attire[guestRole]?.description}</p>
            {
              guestRole === 'coordinator' ?
  
              (
                <ul className='nobullets'>
                  {
                    getParticipatingRoles()
                    .map((role,index) => {
                      return (
                        <li key={index}>
                          <Attire attire={attire[role].attire} header={role}/>
                        </li>
                      )
                    })
                  }
                </ul>
              )
              :
              <Attire attire={attire[guestRole].attire} />
            }
            {(guestRole !== 'serviceVendor' && guestRole !== 'pastor') && (<p className='rem12 text-center'>We understand that finding a specific match is tedious. <br/>Please match the colours as best as you can.</p>)}
            <Entourage role={guestRole} guestDetail={guestDetail}/>
            <CeremonySeatingPlan currentGuest={guestDetail} showNonParticipant={false}/>
            {/* <ProgramView /> */}
            
            <CoordinatorView role={guestRole} />
          </>
        )}
        <LocationInfo guest={guestDetail} printable={printable}/>
        {(<TimeInfo sectionHeaderClassName={sectionHeaderClassName}/>)}
        {(<Gifts />)}
        {guestDetail && !printable && (<OpenBar role={guestRole}/>)}
        { Date.now() >= getTheDate(2024,3,15,19) && (<div className='m-5'><Vendors exclude={['MC']}/></div>)}
      </div>
    );  
}