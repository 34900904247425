export function MenuBar ({...props}, ref) {
    function handleClick(reference) {
      reference?.current?.scrollIntoView();
    }
  
    return (
      <div className='row justify-content-center menu-bar d-none d-sm-none d-lg-flex'>
        <div className='col-12 row' style={{maxWidth:'1600px'}}>
          {
          props.menuButtons.map((item,index )=> {
            return (
              <button className={`${item.text === '' ? 'col-1' : 'col'} button-menu`} key={item.text} onClick={() => handleClick(ref[item.useCase])}>
                <img className='icon mx-1' src={item.icon}/>
                <span className='align-text-top text-capitalize rem18'>{item.text}</span>
              </button>
              );
            })
          }
        </div>
      </div>
    );
  }
