import { useState } from "react";
export function ImageBlur({src, backgroundColor, className, style, onClick }) {
    const [naturalHeight, setNaturalHeight] = useState(0);
    const [naturalWidth, setNaturalWidth] = useState(0);
    const targetImage = new Image();
    // const displayRef = useRef();
    targetImage.addEventListener('load',(event) => {
      setNaturalHeight(event.target.naturalHeight);
      setNaturalWidth(event.target.naturalWidth);
    });
    targetImage.src = src;
    return(
      <>
        {targetImage && 
        (<div onClick={onClick} className={`${className}`} style={{backgroundImage:`url(${src})`, backgroundSize:'contain', 
        left:'50%', top:"50%", transform:'translate(-50%,-50%)',
        width:`${naturalWidth < naturalHeight ? naturalWidth / naturalHeight * 100 + '%' : 'null'}`,
        height:`${naturalHeight < naturalWidth ? naturalHeight / naturalWidth * 100 + '%' : 'null' }`,
        backgroundPosition:'center',backgroundRepeat:'no-repeat', boxShadow:`0px 0px 10px 7px ${backgroundColor} inset`, ...style}}></div>)}
      </>
    );
  }