import { getTheDate } from '../utils/dateUtils';

export const weddingEvent = [
    {
        "name" : "Ceremony",
        "startTime" : getTheDate(2024,3,15,16,0),
        "endTime" : getTheDate(2024,3,15,17,0),
        "requires" : "",
        "subEvents" : [
            {
                "name" : "Procession"
            },
            {
                "name" : "Opening Words & Prayer",
                "subEvents" : [
                    {
                        "name" : "Opening Prayer",
                        "requires" : "Pastor"
                    },
                    {
                        "name" : "Welcome/Statement of Purpose"
                    },
                    {
                        "name" : "The Giving in Marriage"
                    },
                    {
                        "name" : "Lighting of Candles by Sponsors",
                        "requires" : "Candle sponsors to light the 2 candles on the side"
                    },
                    {
                        "name" : "Scripture Reading and Short Message",
                        "requires" : "Pastor"
                    },
                    {
                        "name" : "Charge to the Parents"
                    },
                    {
                        "name" : "Charge to the Sponsors",
                        "requres" : "Primary and Secondary Sponsors"
                    }
                ]
            },
            {
                "name" : "Exchange of Vows"
            },
            {
                "name" : "Exchange of Rings",
                "requires" : "Rings to be handed over to groom"
            },
            {
                "name" : "Blessing of the Bible"
            },
            {
                "name" : "Coin Ceremony",
                "requires" : "Coin sponsors to handover coins to Pastor"
            },
            {
                "name" : "Veil Ceremony",
                "requires" : "Veil sponsor to wrap cord from groom's shoulders extending to brides shoulder"
            },
            {
                "name" : "Cord Ceremony"
            },
            {
                "name" : "Signing of Contract",
                "subEvents" : [
                    {
                        "name" : "Pronouncement of Marriage"
                    },
                    {
                        "name" : "Presentation of the Couple"
                    },
                ]
            },
            {
                "name" : "Lighting of the Unity Candle"
            },
            {
                "name" : "Closing Prayer"
            }
            
        ]
    },
    {
        "name" : "Cocktail Hour",
        "startTime" : getTheDate(2024,3,15,17,0),
        "endTime" : getTheDate(2024,3,15,18,0),
        "subEvents" : [

        ]
    },
    {
        "name" : "Reception",
        "startTime" : getTheDate(2024,3,15,18,0),
        "endTime" : getTheDate(2024,3,16,1,0),
        "subEvents" : [

        ]
    }
]

function getHumanReadbleTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const readableHours = hours > 12 ? hours % 12 : hours;
    const ampm = hours >= 0 && hours < 12 ? 'AM' : 'PM'

    return `${readableHours}:${minutes.toString().padStart(2,'0')} ${ampm}`;

}

export function EventList ({events}) {
    return (
        <>
            <ul>
                {
                    events.map((ev, index) => {
                        return (
                            <li key={index}>
                                {ev.name} 
                                {ev.startTime && (' ---------- ' + getHumanReadbleTime(ev.startTime)) + ' - '} 
                                {ev.endTime && (getHumanReadbleTime(ev.endTime))}
                                {ev.requires && (<span> - Requires: <span className="underline">{ev.requires}</span></span>)}
                                {ev.subEvents && (<EventList events={ev.subEvents}/>)}
                            </li>
                            )
                        })
                    }
            </ul>
        </>
    )
}



export function ProgramView() {
    return (
        <div>
            <p>Event Program</p>
            <EventList events={weddingEvent} />
        </div>
    )
}