const eventRoles = [
    'pastor', 
    'parents', 
    'sponsors', 
    'sponsors_proxy', 
    'best_man', 
    'maid_of_honor',
    'groomsmen', 
    'bridesmaids', 
    'guest',
    'bible_bearer',
    'mc',
    'service_vendor', 
    'host', 
    'coordinator',
    'flower_girls',
    'ring_bearer'];
    
export default eventRoles;