import  { dDay } from '../data/constants.js';
import {getFormattedDate} from '../utils/dateUtils.js';
import eventTimeline from '../data/eventTimeline.js';

export function TimeInfo({sectionHeaderClassName}) {
    return (
      <>
        <div className={sectionHeaderClassName}>Date and Time</div>
        <span className='underline'>{getFormattedDate(dDay)}</span>
        <div className='col-12 col-xl-10 rem12'>
          {
            eventTimeline.map((event,index) => {
              return (
                <div className='row' key={index}>
                  <span className='col-7'>{event.description}</span>
                  <span className='col'>{`${event.from} to ${event.to}`}</span>
                </div>
              )
            })
          }
        </div>
      </>
    );
  }