const eventTimeline = [
    {
      "description" : "Arrival of Guests",
      "from" : "3:45 PM",
      "to" : "4:00 PM"
    },
    {
      "description" : "Ceremony",
      "from" : "4:00 PM",
      "to" : "5:00 PM"
    },
    {
      "description" : "Cocktails",
      "from" : "5:00 PM",
      "to" : "6:00 PM"
    },
    {
      "description" : "Dinner Reception, Program and Dance",
      "from" : "6:00 PM",
      "to" : "1:00 AM"
    }
  ]
export default eventTimeline;