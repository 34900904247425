import { useRef } from "react";
import { sectionHeaderClassName } from "../meta/styleMeta";
import mcc from '../assets/MCC.png';
import venueMap from '../assets/venueMap.png';
import venueDetail from '../data/venueDetail';

export function LocationInfo({guest, printable = false}) {
    const venueMapRef = useRef();
    return (
      <>
        <div className={sectionHeaderClassName}>Location</div>
        <div className='rem16'>
          
            <p>The ceremony and reception will take place in <span className='bold'>{venueDetail.location}</span> at <span className='bold'>{venueDetail.address}</span> - <span className='bold'>{venueDetail.hall}</span> {!printable && (<>Please refer to the <a className='underline bold' 
              onClick={() => venueMapRef.current.scrollIntoView()}>
                Venue Map</a></>)}</p>
          <p></p>
          <p></p>
          {guest && (<p>Please be at the location 15 minutes early before the ceremony.</p>)}
          <div className='row justify-content-center'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.1414804376527!2d-79.70825710000001!3d43.64522470000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3f90c4fa813b%3A0x828f956af7c8da83!2s75%20Derry%20Rd%20W%2C%20Mississauga%2C%20ON%20L5W%201G3!5e0!3m2!1sen!2sca!4v1699909422024!5m2!1sen!2sca" 
            className='col-12 col-md-10 col-lg-8 col-xl-6' height='300px' style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          {
            !printable &&
            (
              <>
                <div className='text-center py-3' ref={venueMapRef}>
                  <span className='bold'>Venue Map</span>
                  <img className='img-thumbnail' src={venueMap}/>
                </div>
                <div className='text-center py-3'>
                  <span className='bold'>Mississauga Convention Centre Main Entrance</span>
                  <img className='img-thumbnail' src={mcc}/>
                </div>
              </>
            )
          }
          
        </div>
      </>
    );
  }