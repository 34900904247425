import { getAllGuestTables, getGuestMessage, getAllGuestDetail } from "./backendWedding";

export function getAllFrontGuestTables(setState) {
    function handleGuestList(guestList) {
        setState(guestList);
    }
    getAllGuestTables(handleGuestList);
}

export function getAllFrontGuestMessages(setState) {
    function handleGuestMessages(guestMessages) {
        setState(guestMessages)
    }
    getGuestMessage(handleGuestMessages);
}

export function getAllFrontGuestDetails(setState) {
    function handleAllGuestDetail(guestDetail) {
        setState(guestDetail);
    }
    getAllGuestDetail('wedding2024',handleAllGuestDetail);
}