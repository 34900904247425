import OPENBAR from '../data/openbar';
import { openBarDay } from '../data/constants.js';
import { sectionHeaderClassName } from '../meta/styleMeta';
import { getFormattedDate, getFormattedDateAndTime, getTheDate } from '../utils/dateUtils';

const alwaysVisibleTo = ['host']

export default function OpenBar({role}) {
    const isVisible = Date.now() >= openBarDay || alwaysVisibleTo.includes(role);

    function openBarItems() {
      return (
        <>
          {
            OPENBAR.map((item,index) => {
              return (
                <div key={index}>
                  <p className='display-6 underline' key={item.title}>{item.title}</p>
                  <ul className='row rem15 nobullets'>
                    {item.selections && openBarSelection(item.selections)}
                  </ul>
                </div>
              );
            })
          }
        
        </>
      );
    }
  
    function openBarSelection(selections) {
      return (
        <>
          {selections.sort().map((selection, index) => {
            return (<li className='col-12 col-md-6 col-xl-4' key={index}>{selection}</li>);
          })}
        </>
      );
    }

    return (
        <>
          <div className={sectionHeaderClassName}>Open Bar Menu</div>
          {!isVisible && (<p className='rem13 px-4'>This will be visible to all on {getFormattedDateAndTime(openBarDay)}</p>)}
          {isVisible && (openBarItems())}
        </>
      );
}