import './App.css';
import './wedding.css';
import { Header } from './components/Header.js' 
import 'bootstrap/dist/css/bootstrap.css';
import { useState, useRef, forwardRef } from 'react';
import { Outlet, BrowserRouter, Routes, Route, Link} from 'react-router-dom';
//Assets
import infoIcon from './assets/info.svg';
import bookIcon from './assets/book.svg';
import imageIcon from './assets/photo.svg';
import envelopeIcon from './assets/envelope.svg';
import openEnvelopeIcon from './assets/envelopeOpen.svg';
//Functional
import { getGuestDetail} from './utils/backendWedding.js';
//Components
import { GuestList } from './components/GuestList.js';
import { EventTablesView, EventTablesViewDetailed, EventTablesViewPrint, range } from './components/EventTables.js';
import { Logistics } from './components/Logistics.js'
import { AttendanceForm } from './components/AttendanceForm.js';
import { Program } from './components/Program';
import { CheckList } from './components/CheckList';
import { SaveTheDateVideo } from './components/SaveTheDateVideo';
import { GuestBook } from './components/GuestBook';
import { Invitation } from './components/Invitation';
import { SideBar } from './components/SideBar';
import { MenuBar } from './components/MenuBar';
import { GuestInformation } from './components/GuestInformation';
import { NoPage } from './components/NoPage';
import { Overlays } from './components/Overlays';
import { Gallery } from './components/Gallery';
import { CsvGuestList } from './components/CsvGuestList';
//Data
import { dDay, k, getFormattedDate} from './data/constants.js';

//Meta
import { sectionDivClassName, sectionHeaderClassName } from './meta/styleMeta';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Common/>}>
            <Route index element={<MainApp/>}/>
            <Route path='*' element={<NoPage/>}/>
            <Route path='guestlist' element={<GuestList/>}/>
            <Route path='tables' element={<EventTablesView/>}/>
            <Route path='tablesDetailed' element={<EventTablesViewDetailed/>}/>
            <Route path='tablesPrinted' element={<EventTablesViewPrint/>}/>
            <Route path='overview' element={<Logistics/>}/>
            <Route path='program' element={<Program/>}/>
            <Route path='admin'>
              <Route path='checklist' element={<CheckList/>}/>
              <Route path='csvguests' element={<CsvGuestList/>}/>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function Common() {
  return (
    <>
      <Outlet/>
    </>
  );
}

function MainApp() {
  const urlParams = new URLSearchParams(window.location.search);
  const initialInvitationId = urlParams.get("invitationId");
  const [currentGuest, setCurrentGuest] = useState(null);
  const defaultMenuOptions = [
    {"text" : "information", "useCase" : "information", "icon" : infoIcon}, 
    {"text": "gallery", "useCase" : "gallery", "icon" : imageIcon}, 
    {"text" : "guestbook", "useCase" : "guestbook", "icon" : bookIcon}];
  const menuOptions = [...defaultMenuOptions];
  const [currentMenu, setCurrentMenu] = useState(menuOptions);
  
  const sectionRefs = {
    "timeline" : useRef(),
    "invitation" : useRef(),
    "information" : useRef(),
    "gallery" : useRef(),
    "guestbook" : useRef()
  }
  
  function guestFoundHandler(guestDetails) {
    setCurrentGuest(guestDetails);
    if(guestDetails?.name !== undefined && guestDetails?.name !== null) {
      if(guestDetails.attendance == null)  {
        setCurrentMenu([{"text": "", "useCase" : "invitation", "icon" : envelopeIcon}, ...defaultMenuOptions])
      } else {
        setCurrentMenu([{"text": "", "useCase" : "invitation", "icon" : openEnvelopeIcon}, ...defaultMenuOptions])
      }
    }
  }
  
  if(currentGuest === null && initialInvitationId !== null) {
    getGuestDetail(initialInvitationId, guestFoundHandler);
  }

  return (
    <div className="App serif">
      <MenuBarRef menuButtons={currentMenu} ref={sectionRefs}/>
      <div className='container-lg main-container'>
        <div className='row'>
          <div className='col-11 col-lg-12'>
            <Header />
            <SaveTheDateVideo />
            {currentGuest && (<InvitationRef guest={currentGuest} ref={sectionRefs.invitation}/>)}
            {currentGuest && (<AttendanceForm guest={currentGuest} guestHandler={guestFoundHandler}/>)}
            <GuestInformationRef currentGuest={currentGuest} ref={sectionRefs.information}/>
            <GalleryRef ref={sectionRefs.gallery}/>
            <GuestBookRef guest={currentGuest} ref={sectionRefs.guestbook}/>
          </div>
          <div className='d-lg-none col-1'>
            <SideBarRef menuButtons={currentMenu} ref={sectionRefs}/>
          </div>
        </div>
      </div>
      <Overlays/>
    </div>
  );
}

const MenuBarRef = forwardRef(MenuBar);
const SideBarRef = forwardRef(SideBar);
const GuestInformationRef = forwardRef(GuestInformation);
const InvitationRef = forwardRef(Invitation);
const GuestBookRef = forwardRef(GuestBook);
const GalleryRef = forwardRef(Gallery);





