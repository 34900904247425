import { useState } from 'react';
import { getGuestDetail } from "../utils/backendWedding";
import 'bootstrap/dist/css/bootstrap.css';
import '../wedding.css';
import '../styles/playbook.css'



export function Program({role}) {
    const urlParams = new URLSearchParams(window.location.search);
    const invitationId = urlParams.get("invitationId");
    const [currentGuest, setCurrentGuest] = useState(null); 

    function guestFoundHandler(guestDetails) {
        setCurrentGuest(guestDetails);
      }

    if(currentGuest === null && invitationId !== null) {
        getGuestDetail(invitationId, guestFoundHandler);
    }

    function view() {
        
        return (
            <div id='bg'>
                <div id='container' className="container">
                    <iframe src="https://docs.google.com/document/d/e/2PACX-1vRWP3T1ocO6L7QshwYwtcvgYuQSoZ1vea1jsSNr2FBP5IceoZIBxHSsPGv73ntzAenixKQN-Iy3m5fo/pub?embedded=true"/>
                </div>
            </div>
        )
    }

    function redirectHome() {
        const hostname = window.location.host.includes("localhost") ? "http://localhost:3000" : "https://www.kristellevirson.ca";
        window.location.href = `${hostname}/?invitationId=${invitationId}`;
    }

    if(currentGuest?.eventRole === 'guest') {
        redirectHome();
    } else {
        return view();
    }
}