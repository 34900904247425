import { sectionDivClassName } from "../meta/styleMeta";
export function Invitation({guest}, ref) {
    if(guest !== null) {
      return (
        <div className={sectionDivClassName} ref={ref}>
          <div className='pt-5'>
            <span className='rem13'>
              <div className='d-flex justify-content-start ps-2'>Hello {guest.name},</div>
              
              <div className='d-flex justify-content-center px-5 py-3'>{guest.message}</div>
              <div className='d-flex justify-content-start ps-5'>
                Kristelle & Virson
              </div>
            </span>
          </div>
        </div>
      );
    }
    return <></>;  
}