import { useState } from "react";
import { toTitleCase } from "../utils/textUtils";
import { Pallete } from "./Pallete";
export function Attire({attire, header}) {
    const isHidden = header !== undefined ;
    const [hidden, setHidden] = useState(isHidden);
    return (
      <>
        {
          header && (
            <button className='bold rounded'
            style={{color: `steelblue`, backgroundColor:`rgba(255,255,255)`, border:`1px steelblue solid`}}
            onClick={()=> setHidden(!hidden)}>{toTitleCase(header.replaceAll('_', ' '))}</button>
          )
        }
        {
          (!hidden) && (
            <ul>
            {attire.map(item => {
              const html = {__html:item.type};
              return (
              <span key={item.type} className='rem16'>
                <li dangerouslySetInnerHTML={html}></li>
                <Pallete colors={item.colors} />
              </span>
              );
            })}
            </ul>
          )
        }
      </>
    );
}