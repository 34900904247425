import { json } from "react-router-dom";


export async function getGuestDetail(invitationId = null, handler) {
    if(invitationId !== null) {
        // invitationId = String(invitatonId).trim();
        async function fetchGuestData() {
            let guestData = await fetch(`https://kristellevirson.ca/api/invitation.php?invitationId=${invitationId}`, {
                method:"GET"
            })
            .then(response => response.text())
            .then(text => {
                try {
                    return JSON.parse(text);
                } catch (err) {
                    return undefined;
                }
            })
            .catch(error => {
                console.log(error);
            });            
            handler(guestData);
        }
        fetchGuestData();
    } else {
        handler(null);
    }
}

export async function getAllGuestDetail(token = null, handler) {
    if(token !== null) {
        async function fetchAllGuestData() {
            let guestData = await fetch(`https://kristellevirson.ca/api/invitation.php?token=${token}`, {
                method:"GET"
            })
            .then(response => response.text())
            .then(text => {
                try {
                    return JSON.parse(text);
                } catch (err) {
                    return null;
                }
            })
            .catch(error => {
                console.log(error);
            })
            handler(guestData)
        }
        fetchAllGuestData();
    } else {
        handler(null);
    }
}

export async function getAllGuestTables(handler) {
    async function fetchAllGuestData() {
        let guestData = await fetch(`https://kristellevirson.ca/api/invitation.php?req=tables`, {
            method:"GET"
        })
        .then(response => response.text())
        .then(text => {
            try {
                return JSON.parse(text);
            } catch (err) {
                return null;
            }
        })
        .catch(error => {
            console.log(error);
        })
        handler(guestData)
    }
    fetchAllGuestData();
}

export async function updateAttendance(invitationId, attendance, handler) {
    if(invitationId !== null && attendance !== null) {
        const jsonBody = {
            "invitationId":`${invitationId}`,
            "attendance":attendance
        };

        async function updateAttendanceDetail() {
            fetch('https://kristellevirson.ca/api/invitation.php', {
                method:"POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(jsonBody)
            })
            .then(res => {
                if(res.status === 200) {
                    handler();
                } else {
                    console.log("Something went wrong");
                }
            })
        }
        updateAttendanceDetail();
    }
}

export async function updateGuest(guest, handler) {
    if(guest !== null && guest.invitationId !== null) {
        
        async function updateGuestDetail() {
            fetch('https://kristellevirson.ca/api/invitation.php', {
                method:'POST',
                header: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(guest)
            })
            .then(response => {
                if(response.status === 200) {
                    console.log(response.status);
                    handler();
                } else {
                    console.log(`Update Guest responded with ${response.status}`)
                }
            })
        }
        updateGuestDetail();
    }
}

export async function createGuest(guest, handler) {
    if(guest !== null 
        && guest.name !== null && guest.name !== ''
        && guest.guestAllocation !== null
        && guest.tableNumber !== null) {
            console.log(guest)
            async function addGuest() {
                fetch('https://kristellevirson.ca/api/invitation.php', {
                method:'POST',
                header: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(guest)
            })
            .then(response => {
                handler(guest);
            })
            }
            addGuest();
        }
}

export async function getGuestMessage(handler) {
    async function fetchAllGuestMessage() {
        let guestMessages = await fetch(`https://kristellevirson.ca/api/guestmessage.php`, {
            method:"GET"
        })
        .then(response  => response.text())
        .then(text => {
            try {
                return JSON.parse(text);
            } catch (err) {
                return null;
            }
        })
        .catch(error => {
            console.log(error);
        })
        handler(guestMessages)
    }
    fetchAllGuestMessage();
}

export async function createGuestMessage(data, handler) {
    if(data !== null 
        && data.invitationId !== null
        && data.displayName !== null && data.displayName !== ''
        && data.message !== null) {
            async function addGuestMessage() {
                fetch('https://kristellevirson.ca/api/guestmessage.php', {
                    method:'POST',
                    header: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => {
                    handler();
                })
            }
            addGuestMessage();
        }
}

export async function getGalleryImages(topic, handler) {
    const galleryPath = `https://kristellevirson.ca/api/resources/image/gallery/${topic !== null ? topic + "/" : ""}`

    async function fetchGalleryImages() {
        let data = await fetch(`https://kristellevirson.ca/api/gallery.php?topic=${topic}`, {
            method:'GET'
        })
        .then(response => {
            return response.text()
        })
        .then(text => {
            try {
                let images =  JSON.parse(text);
                return images.map((item) => {
                    return galleryPath + item;
                })
            } catch (err) {
                return null;
            }
        })
        .catch(error => {
            console.log(error);
        })
        handler(data);
    }
    fetchGalleryImages();
}

export async function getStory(handler) {
    async function fetchStory() {
        let data = await fetch('https://kristellevirson.ca/api/story.php', {
            method:'GET'
        })
        .then(response => {
            return response.text()
        })
        .then(text => {
            try {
                let data =  JSON.parse(text);
                return data;
            } catch (err) {
                return null;
            }
        })
        .catch(error => {
            console.log(error);
        })
        handler(data);
    }
    fetchStory();
}