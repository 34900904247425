import announcements from "../data/announcements";

export function Announcement() {
    return (
      <>
        {announcements.length > 0 && (<div className='display-6 py-3 cursive'>Updates</div>)}
        <ul>
          {
            announcements.map(item => {
              return (
                <li><span>{item.date}</span> - <span>{item.msg}</span></li>
              );
            })
          }
        </ul>
      </>
    );
  }