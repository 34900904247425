export function SideBar (props, ref) {

    function handleClick(reference) {
      reference?.current?.scrollIntoView();
    }
  
    return (
      <div id='sideBar' className='d-lg-none'>
        {
          props.menuButtons.map((item, index) => {
            return (
              <button key={item.text} className='sideButton my-1' onClick={() => handleClick(ref[item.useCase])}>
                <img className='icon' src={item.icon}/>
              </button>
            );
          })
        }
      </div>
    );
  }