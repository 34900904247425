import { useState, useRef, forwardRef } from 'react';

export function Logistics() {
    // const [logistic, setLogistic] = useState(null);

    // function handler(logistics) {
    //     setLogistic(logistics);
    // }

    // getLogistics(handler);

    return(
        <div className="row p-5">
            {
                logistic?.map((plan, index) => {
                    return (
                        <div className="col-12" key={plan.title}>
                            <div className="h1">
                                {plan.title}
                            </div>  
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Event Description</th>
                                        <th>Required Person</th>
                                        <th>Required Resource</th>
                                    </tr>   
                                </thead>
                                <tbody>
                                {plan.timeline.map((event, index) => {
                                    return (
                                        <tr key={event.time + index}>
                                            <td>{event.time}</td>
                                            <td>
                                                <ul>
                                                    {event?.event?.map((val,index) => <li key={index}>{val}</li>)}
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    {event?.requiredPerson?.map((val,index) => <li key={index}>{val}</li>)}
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    {event?.requiredResource?.map((val,index) => <li key={index}>{val}</li>)}
                                                </ul>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    );
                })
            }
        </div>
    );
}


// async function getLogistics(handler) {
//     async function fetchData() {
//         let guestData = await fetch(`kristellevirson.ca/api/resources/logistics.json`, {
//             method:"GET",
//             mode: 'no-cors'
//         })
//         .then(response => {
//             console.log(response);
//             return response.text()
//         })
//         .then(text => {
//             try {
//                 return JSON.parse(text);
//             } catch (err) {
//                 return undefined;
//             }
//         })
//         .catch(error => {
//             console.log(error);
//         });            
//         handler(guestData);
//     }
//     fetchData();
// }    





const logistic = [
    {
        "title" : "Plan A (Good Weather)",
        "timeline" : [
            {
                "time" : "12:00 PM",
                "event" : ["Venue Decoration"],
                "requiredPerson" : ['decorator'],
                "requiredResource" : ['decorations']
            },
            {
                "time" : "12:30 PM",
                "event" : ["Groom Preparation"],
                "requiredPerson" : ['photographer'],
                "requiredResource" : ['Hotel Room']
            },
            {
                "time" : "1:30 PM",
                "event" : ["Bridal Preparation / Photo session"],
                "requiredPerson" : ['photographer', 'make-up artist'],
                "requiredResource" : ['Hotel Room']
            },
            {
                "time" : "3:45 PM",
                "event" : ["Expected All Guest Arrival"]
            },
            {
                "time" : "3:40 PM",
                "event" : ["Bride leaves hotel for the Venue"],
                "requiredPerson" : ["driver"]
            },
            {
                "time" : "4:00 PM",
                "event" : ["Ceremony Starts", "Entourage Entrance"],
                "requiredPerson" : ['photographer', 'pastor', 'MC', 'Bridesmaids', 'Groomsmen', 
                        'Sponsors','Flower Girl', 'Ring Bearer'],
                "requiredResource" : ["microphone", "speaker", 'Laptop for entry music']
            },
            {
                "time" : "5:00 PM",
                "event" : ["Expected Ceremony End", "Guest leaves the room to have coctail outside",
                        "Photobooth???", "DJ Preparation"],
                "requiredPerson" : ['photographer', 'MC', 'VenueStaff'],
                "requiredResource" : ["microphone", "Photobooth"]
            },
            {
                "time" : "6:00 PM",
                "event" : ["Expected Reception Start", "Guest leaves the room to have coctail outside",
                    'Entourage entrance', "Newly Wed Entrance"],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator)'],
                "requiredResource" : ["microphone", "speaker", "Spotlight"]
            },
            {
                "time" : "6:30 PM",
                "event" : ["Welcome Speech", "Open Bar", "Reception Meal/Dinner", 'Projector Video'],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator)'],
                "requiredResource" : ["microphone", "speaker", "Spotlight", "Projector"]
            },
            {
                "time" : "7:30 PM",
                "event" : ["Toast Speeches", 'First Dance', "Cake Cutting"],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator)'],
                "requiredResource" : ["microphone", "speaker", "Spotlight", "Projector", 'Smoke Machine']
            },
            {
                "time" : "8:00 PM",
                "event" : ["Bouquet and Garter Toss", "Party"],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator / Pre-program)'],
                "requiredResource" : ["microphone", "speaker", "Dance Light"]
            }
        ]
    },
    {
        "title" : "Plan B (Bad Weather)",
        "timeline" : [
            {
                "time" : "12:00 PM",
                "event" : ["Venue Decoration"],
                "requiredPerson" : ['decorator'],
                "requiredResource" : ['decorations']
            },
            {
                "time" : "12:30 PM",
                "event" : ["Groom Preparation"],
                "requiredPerson" : ['photographer'],
                "requiredResource" : ['Hotel Room']
            },
            {
                "time" : "1:30 PM",
                "event" : ["Bridal Preparation / Photo session"],
                "requiredPerson" : ['photographer', 'make-up artist'],
                "requiredResource" : ['Hotel Room']
            },
            {
                "time" : "3:45 PM",
                "event" : ["Expected All Guest Arrival"]
            },
            {
                "time" : "3:40 PM",
                "event" : ["Bride leaves hotel for the Venue"],
                "requiredPerson" : ["driver"]
            },
            {
                "time" : "4:00 PM",
                "event" : ["Ceremony Starts", "Entourage Entrance"],
                "requiredPerson" : ['photographer', 'pastor', 'MC', 'Bridesmaids', 'Groomsmen', 
                        'Sponsors','Flower Girl', 'Ring Bearer'],
                "requiredResource" : ["microphone", "speaker", 'Laptop for entry music']
            },
            {
                "time" : "5:00 PM",
                "event" : ["Expected Ceremony End", "Guest leaves the room to have coctail outside",
                        "Photobooth???", "DJ Preparation"],
                "requiredPerson" : ['photographer', 'MC', 'VenueStaff'],
                "requiredResource" : ["microphone", "Photobooth"]
            },
            {
                "time" : "6:00 PM",
                "event" : ["Expected Reception Start", "Guest leaves the room to have coctail outside",
                    'Entourage entrance', "Newly Wed Entrance"],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator)'],
                "requiredResource" : ["microphone", "speaker", "Spotlight"]
            },
            {
                "time" : "6:30 PM",
                "event" : ["Welcome Speech", "Open Bar", "Reception Meal/Dinner", 'Projector Video'],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator)'],
                "requiredResource" : ["microphone", "speaker", "Spotlight", "Projector"]
            },
            {
                "time" : "7:30 PM",
                "event" : ["Toast Speeches", 'First Dance', "Cake Cutting"],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator)'],
                "requiredResource" : ["microphone", "speaker", "Spotlight", "Projector", 'Smoke Machine']
            },
            {
                "time" : "8:00 PM",
                "event" : ["Bouquet and Garter Toss", "Party"],
                "requiredPerson" : ['Photographer', 'DJ', 'MC', 'Digital X Tech (Light Operator / Pre-program)'],
                "requiredResource" : ["microphone", "speaker", "Dance Light"]
            }
        ]
    }
]