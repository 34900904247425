const OPENBAR = [
    {
      'title' : 'HIS DRINK',
      'selections' : ['Blue Hawaii'] 
    },
    {
      'title' : 'HER DRINK',
      'selections' : ['Mojito'] 
    },
    {
      'title' : 'Specialty Cocktails',
      'selections' : ['Margaritas', 'Pina Colada', 'Daiquiris'] 
    },
    {
      'title' : 'Gautier Cognac VS',
      'selections' : ['Du Jardin VSOP Brandy']
    },
    {
      'title' : 'Imported and Domestic Beer',
      'selections' : ['Budweiser', 'Canadian', 'Export', 'Coors Light', 'Corona', 'Heineken', 'Stella Artois']
    },
    {
      'title' : 'House Red and White Table Wines'
    },
    {
      'title' : 'None Alcoholic Drinks',
      'selections' : ['Juices','Soft Drinks', 'Espresso']
    },
    {
      'title' : 'Spirit | Liquor | Liqueur Selections',
      'selections' : ['Grey Goose Vodka','Wiser’s Special','Whiskey','Crown Royal','Beefeater','Dry Gin',
      'Black Label Scotch','Malibu Coconut Rum','Southern','Comfort','Sambuca Nostra','Dubonnet','Campari',
      'Amaretto','Reserve Porto','Cacao Dark','Jägermeister','Crème De Banana','Polar Ice / Skyy Vodka',
      'Limoncello','Canadian Club Whiskey','Dry Martini','Lambs White Rum','Anisette','Gautier Cognac',
      'Blue Curacao','Triple Sec','Jack Daniels','Chivas Regal','Grand Marnier','Ballantine’s Scotch',
      'Melon Liqueur','Peach Schnapps','Dry Vermouth','O’Casey’s Irish Cream','Grand Marnier',
      'Banana Liqueur','Bacardi Dark Rum','Barclay’s Brandy','Sweet Vermouth','Grappa','Stravecchia',
      'Olmeca Gold Tequila','Bolivar Coffee Liqueur','Long Island','Ice Tea','Grappa Stravecchia',
      'Crème de Cacao White']
    }
  ]
export default OPENBAR;