import { sectionDivClassName, sectionHeaderClassName } from "../meta/styleMeta";
import { GalleryTopic } from "./GalleryTopic";
export function Gallery(props, ref) {
    return (
      <>
        <div className={sectionDivClassName} ref={ref}>
          <div className={sectionHeaderClassName}>Gallery</div>
          <GalleryTopic title='prenup' topic='prenup' thumb={true}/>
          <GalleryTopic title='boracay, philippines' topic='boracay' thumb={true}/>
        </div>
      </>
    );
}