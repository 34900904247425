import { confirmBy, dDay} from '../data/constants.js';
import {getFormattedDate} from '../utils/dateUtils.js';
import { useState } from 'react';
import { getGuestDetail, updateGuest } from '../utils/backendWedding.js';
import 'bootstrap/dist/css/bootstrap.css';
import '../wedding.css'

export function AttendanceForm({guest, guestHandler}) {
    const [currentGuest, setCurrentGuest] = useState(guest);
    const [attendance, setAttendance] = useState(currentGuest.attendance);
    const [allergy, setAllergy] = useState(currentGuest.allergy);
    const formVisible = getConfirmDate()>=Date.now() && currentGuest.attendance === null;
    
    function successUpdateHandler() {
      getGuestDetail(guest.invitationId, (guestData) => {
        setCurrentGuest(guestData);
        guestHandler(guestData); 
      });
    }
  
    function attendanceSubmit() {
        const currentGuestCopy = {...currentGuest};
        currentGuestCopy.attendance = attendance;
        currentGuestCopy.allergy = allergy;

        updateGuest(currentGuestCopy, successUpdateHandler);
    
        
    //   updateAttendance(currentGuest.invitationId, attendance, successUpdateHandler);
    }

    function getConfirmDate() {
        const confirmDate = currentGuest.confirmBy;
        const strConfirmDate = currentGuest.confirmBy.split("-");
        const date = new Date(strConfirmDate[0],parseInt(strConfirmDate[1]) + -1, strConfirmDate[2]);
        return date;
    }
  
    if(formVisible) {
        getConfirmDate();

        return (
            <div className='section p-4 form rem13'>
                <div className='display-6 py-3 cursive'>RSVP</div>
                <div className='text-center pb-3'>Please confirm your attendance on or before <span className='italic bold'>{getFormattedDate(getConfirmDate())}</span> for reservation. Thank you.</div>
                <div className='form-check'>
                    <span className='row justify-content-center my-2'>
                        <button className={`col-10 col-sm-8 col-md-4 col-xl-3 rounded btn ${attendance === 1 ? 'btn-primary' : 'btn-light'}`} onClick={() => setAttendance(1)} >
                            I'll be there                    
                        </button>
                    </span>
                    <span className='row justify-content-center pb-3 my-2'>
                        <button className={`col-10 col-sm-8 col-md-4 col-xl-3 rounded btn ${attendance === 0 ? 'btn-primary' : 'btn-light'}`} onClick={() => setAttendance(0)} >
                            Unable to Attend
                        </button>
                    </span>
                    <hr/>
                    <span className='text-center py-5'>
                        Do you have any <span className='bold'>food allergy?</span> Are you a <span className='bold'>vegetarian</span>? If any of these applies to you please let us know.
                    </span>
                    <span className='row justify-content-center py-4'>
                        <textarea className='col-10 col-sm-10 col-md-10 col-lg-8' defaultValue={allergy} placeholder='E.g. Allergic to Dairy, Shrimp / Vegetarian' onChange={(event) => setAllergy(event.target.value)}/>
                    </span>
                    <span className='row justify-content-center py-3'>
                        <input type='button' className='btn btn-primary col-12 col-sm-8 col-md-4 col-xl-3 ' value={currentGuest.attendance !== null ? "Confirmed" : "Confirm"} onClick={attendanceSubmit} disabled={attendance === null}/>
                    </span>
                    <div className='text-center bold'>You have {currentGuest.allocation > 1 ? currentGuest.allocation + " seats": + currentGuest.allocation + " seat"} in this event</div>
                    <div className='underline text-center rem10'>Please do not share your invitation link as this is unique to you. Other invited guests will have their own link</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='form p-4 section rem13'>
                {currentGuest.attendance === 0 ? (<div className='text-center bold'>You have confirmed your no attendance in this event.</div>) : (<div className='text-center bold'>You have {currentGuest.allocation > 1 ? currentGuest.allocation + " seats": + currentGuest.allocation + " seat"} in this event</div>)}
                <span className='d-flex text-center justify-content-center rem13'>Thank you for confirming your attendance. {currentGuest.attendance === 1 ? `See you on ${getFormattedDate(dDay)}.` : `Your presence will be missed.`} </span>
            </div>
        );
    }
  }