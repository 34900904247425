import {forwardRef} from 'react'
import headerIcon from '../assets/headerIcon.png';

export const Header = forwardRef(function Header ({onClick, printable = false}, ref) {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  return (
    <div className='header text-center ratio ratio-1x1' onClick={onClick} 
    style={{position:'relative'}}>
      <div className='header-icon' style={{
        backgroundImage:`url(${headerIcon})`, backgroundRepeat:'no-repeat', backgroundPosition:'center',
        backgroundSize:'contain',
        filter: `${!printable && ('invert(47%) sepia(73%) saturate(346%) hue-rotate(164deg) brightness(88%) contrast(94%)')}`}}>
      </div>
      {/* <div className='testborder-green' style={{position:'absolute', zIndex:'100'}}> */}
        {/* <Logo classType={'header-logo testborder-blue'} style={{width:'50rem'}}/> */}
        <div>
          <div className={'display-3 header-logo'} style={{zIndex:'100', position:'relative', top:'50%'}}>
            <span className='row'>
              <span className='col-12'>Kristelle Bondad</span>
            </span>
            <span className='row'>
              <span className='col-12'>&</span>
            </span>
            <span className='row'>
              <span className='col-12'>Virson Rulona</span>
            </span>
            <div className='py-5'>
              <span className='col-12 cursive display-3 header-logo-2 display-6'>March 15, 2024</span>
            </div>
          </div>
        </div>
      {/* </div> */}
      {/* <div className='' style={{position:'absolute',
      top:'50$',
      width:`${windowWidth > windowHeight ? 'auto' : windowWidth + 'px'}`, 
      height:`${windowHeight > windowWidth ? 'auto' : windowHeight + 'px'}`,
      aspectRatio:'1/1',
      backgroundImage:`url(${headerIcon})`, backgroundRepeat:'no-repeat', backgroundPosition:'center',
      backgroundSize:'contain'}}></div> */}
    </div>
  );
})

export function Logo ({classType, style}) {
  return (
    <div className={'display-3 ' + classType} style={{...style}}>
      <span className='row'>
        <span className='col-12'>Kristelle Bondad</span>
      </span>
      <span className='row'>
        <span className='col-12'>&</span>
      </span>
      <span className='row'>
        <span className='col-12'>Virson Rulona</span>
      </span>
    </div>
  );
}