import { displayProgram } from "../data/constants";

const hostname = window.location.hostname.includes("localhost") ? "http://localhost:3000" : "https://www.kristellevirson.ca";


export function ProgramButton({viewingRole, invitationId}) {
  const visibleToRoles = ["host", "parents", "pastor", "sponsors", "sponsors_proxy", "best_man", "maid_of_honor", 
  "groomsmen", "bridesmaids", "coordinator", "flower_girls", "ring_bearer", "mc"];
  const isVisible = (visibleToRoles.includes(viewingRole) && Date.now() >= displayProgram) || viewingRole === 'host';
    return (
      <>
        {isVisible && (<button className='btn btn-outline-primary my-5'
            onClick={()=>window.open(`${hostname}/program?invitationId=${invitationId}`,'_blank')}
            title='View Program'><span className='rem16'>Program</span></button>)}
      </>
    )
  }