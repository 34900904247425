const ATTIRE = {
    "disclaimer" : "",
    "host" : {
      "description" : "Wedding Gown and Suit",
      "attire" : []
    },
    "common" : {
        "description" : "This is a formal event. We encourage guests to wear dress for the ladies and a suit for the gentlemen."
    },
    "pastor" : {
      "description" : "As the pastor that will officiate the wedding, you are to wear what you deem is appropriate for the marriage. For your knowledge however below are the guest attire information.",
      "attire" : [
        {
          "type" : 'Gentlemen may wear a <span class="bold" style="color:black">black</span> suit and a necktie of any of the following colour;<br/>Ladies may wear a dress of any of the following colour:',
          "colors" : ['rgb(90, 109, 128)','rgb(139, 206, 215)','rgb(53, 113, 154)','rgb(37, 81, 127)', 'rgb(47,105,207)']
        }
      ]
  }, 
    "guest" : {
        "description" : "",
        "attire" : [
          {
            "type" : 'Gentlemen may wear a <span class="bold" style="color:black">black</span> suit and a necktie of any of the following colour;<br/>Ladies may wear a dress of any of the following colour:',
            "colors" : ['rgb(90, 109, 128)','rgb(139, 206, 215)','rgb(53, 113, 154)','rgb(37, 81, 127)', 'rgb(47,105,207)']
          }
        ]
    }, 
    "mc" : {
        "description" : "",
        "attire" : [
          {
            "type" : 'Gentlemen may wear a <span class="bold" style="color:black">black</span> suit and a necktie of any of the following colour;<br/>Ladies may wear a dress of any of the following colour:',
            "colors" : ['rgb(90, 109, 128)','rgb(139, 206, 215)','rgb(53, 113, 154)','rgb(37, 81, 127)', 'rgb(47,105,207)']
          }
        ]
    }, 
    "flower_girls" : {
      "description" : "",
      "attire" : [
        {
          "type" : 'Gentlemen may wear a <span class="bold" style="color:black">black</span> suit and a necktie of any of the following colour;<br/>Ladies may wear a dress of any of the following colour:',
          "colors" : ['rgb(90, 109, 128)','rgb(139, 206, 215)','rgb(53, 113, 154)','rgb(37, 81, 127)', 'rgb(47,105,207)']
        }
      ]
    }, 
    "ring_bearer" : {
      "description" : "",
      "attire" : [
        {
          "type" : 'Gentlemen may wear a <span class="bold" style="color:black">black</span> suit and a necktie of any of the following colour;<br/>Ladies may wear a dress of any of the following colour:',
          "colors" : ['rgb(90, 109, 128)','rgb(139, 206, 215)','rgb(53, 113, 154)','rgb(37, 81, 127)', 'rgb(47,105,207)']
        }
      ]
    }, 
    "groomsmen" : {
        "description" : "As one of the groomsmen, you will be wearing the following:",
        "attire" : [
          {
            "type" : '<span class="bold" style="color:brown">Brown</span> shoes and a suit of the following colour:',
            "colors" : ['rgb(150,151,171)']
            // "colors" : ['rgb(153,169,195)']
          },
          {
            "type" : 'A necktie of the following colour:',
            "colors" : ['rgb(90,110,152)']
          }
        ]
    },
    "best_man" : {
      "description" : "As one of the best man, you will be wearing the following:",
      "attire" : [
        {
          "type" : '<span class="bold" style="color:brown">Brown</span> shoes and a suit of the following colour:',
          "colors" : ['rgb(150,151,171)']
          // "colors" : ['rgb(153,169,195)']
        },
        {
          "type" : 'A necktie of the following colour:',
          "colors" : ['rgb(90,110,152)']
        }
      ]
  },
    "bridesmaids" : {
        "description" : "As one of the bridesmaids, you will be wearing the following:",
        "attire" : [
          {
            "type" : 'A dress of the following colour:',
            "colors" : ['rgb(90,110,152)']
          }
        ]
    },
    "maid_of_honor" : {
      "description" : "As the maid of honour, you will be wearing the following:",
      "attire" : [
        {
          "type" : 'A dress of the following colour:',
          "colors" : ['rgb(90,110,152)']
        }
      ]
  },
    "sponsors" : {
        "description" : "As one of the sponsors, you will be wearing the following:", 
        "attire" : [
          {
            "type" : 'Gentlemen may wear a <span class="bold" style="color:black">black</span> suit with a necktie of the following colour;<br/>The ladies may wear a dress of the following colour:',
            "colors" : ['rgb(138, 154, 91)']
          }
        ]
    },
    "sponsors_proxy" : {
      "description" : "You are representing a sponsor, you will be wearing the following:",
      "attire" : [
        {
          "type" : 'On behalf of a sponsor you are representing, gentlemen may wear a <span class="bold" style="color:black">black</span> suit with a necktie of the following colour;<br/>The ladies may wear a dress of the following colour:',
          "colors" : ['rgb(138, 154, 91)']
        },
        {
          "type" : 'Otherwise for regular guests, gentlemen may wear a <span class="bold" style="color:black">black</span> suit and a necktie of any of the following colour;<br/>Ladies may wear a dress of any of the following colour:',
          "colors" : ['rgb(90, 109, 128)','rgb(139, 206, 215)','rgb(53, 113, 154)','rgb(37, 81, 127)', 'rgb(47,105,207)']
        }
      ]
    },
    "parents" : {
      "description" : "As the parents, you will be wearing the following:",
      "attire" : [
        {
          "type" : 'The father may wear a <span class="bold" style="color:black">black</span> suit with a necktie of the following colour;<br/> The mother may wear a dress of the following colour:',
          "colors" : ['royalblue']
        }
      ]
    },
    "service_vendor" : {
        "description" : "You are not obligated to wear anything in specific but please do wear clothes appropriate to your role and the event.",
        "attire" : []
    },
    "coordinator": {
      "description": "As our wedding coordinator, feel free to wear clothes what you deem appropriate to your role and the event. Below are the list of attires of guests for your information:",
      "attire": []
    }
  };
export default ATTIRE;