export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getTheDate(year, month, day, hour = 0, minute = 0) {
    const theDate = new Date(year,month-1,day)
    theDate.setHours(hour, minute);
    return theDate;
  }

export function getFormattedDate(date) {
    return `${DAYS[date.getDay()]}, ${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export function getFormattedDateAndTime(date) {
  return `${DAYS[date.getDay()]}, ${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} @ ${getTime(date.getHours(), date.getMinutes().toString().padStart(2, '0'))}`;
};

function getTime(hour, minutes) {
  const amfm = hour < 12 || hour === 24 ? 'AM' : 'PM';
  const displayHour = hour <=12 ? hour : hour % 12;
  return `${displayHour}:${minutes} ${amfm}`;
}