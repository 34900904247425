import { sectionHeaderClassName } from "../meta/styleMeta";
import { Vendors } from "./Vendors";
import { EventTablesView } from "./EventTables";
import floorPlan from '../assets/ReceptionFloorPlan.png';

export function CoordinatorView({role}) {
    const visibleToRole = ['host', 'coordinator']
    return (
      visibleToRole.includes(role) && (
        <div className='m-5'>
          <div className={sectionHeaderClassName}>Coordinator's Information</div>
          <p className='rem italic'>This part is only viewable to the following: {visibleToRole.join(", ")}</p>
          
          <Vendors exclude={['Wedding Coordinator']}/>
          <EventTablesView />
          <p>Table arrangement folllowing a floor layout such as below</p>
          <img className='img-fluid' src={floorPlan}/>
        </div>
      )
    )
}