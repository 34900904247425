import { useState } from "react";
export function Pallete({colors}) {
    const [fullScreen, setFullScreen] = useState(false);
    const [fullScreenColor, setFullScreenColor] = useState('transparent');
    return(
      <div>
        <span className='rem11 ps-4'>Click on the pallete to view colour in fullscreen</span>
        <div className='row ms-2'>
          {colors.map(color => {
            return (
                <div className='m-3' key={color} style={{borderRadius:'50%', height:'50px', width:'50px', 
                backgroundColor:`${color}`, border:'solid 1px black'}} onClick={() => 
                {
                  setFullScreen(!fullScreen)
                  setFullScreenColor(color)
                }}>
                </div>
              );
          })}
          { fullScreen && (
            <div className='justify-content-center d-flex align-content-center' onClick={() => setFullScreen(!fullScreen)}
            style={{zIndex:'2000',position:'fixed',top:'0px',left:'0px',bottom:'0px',right:'0px', color:'white', backgroundColor:`${fullScreenColor}`}}>
              <span>Click Anywhere to Close</span>
            </div>
          )}
        </div>
      </div>
    );
  }